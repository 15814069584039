import { z } from 'zod';
// Alteration state
export var AlterationStateKey;
(function (AlterationStateKey) {
    AlterationStateKey["AlterationState"] = "alterationState";
})(AlterationStateKey || (AlterationStateKey = {}));
export const alterationStateGuard = Object.freeze({
    [AlterationStateKey.AlterationState]: z.object({
        timestamp: z.number(),
        updatedAt: z.string().optional(),
    }),
});
export const systemKeys = Object.freeze(Object.values(AlterationStateKey));
export const systemGuards = Object.freeze({
    ...alterationStateGuard,
});
