export const userInfoSelectFields = Object.freeze([
    'id',
    'username',
    'primaryEmail',
    'primaryPhone',
    'name',
    'avatar',
    'customData',
    'identities',
    'lastSignInAt',
    'createdAt',
    'applicationId',
    'isSuspended',
]);
export var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "admin";
    /** Common user role in admin tenant. */
    UserRole["User"] = "user";
})(UserRole || (UserRole = {}));
export var PredefinedScope;
(function (PredefinedScope) {
    PredefinedScope["All"] = "all";
})(PredefinedScope || (PredefinedScope = {}));
