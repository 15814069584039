@use '@/scss/underscore' as _;

/* Preview Settings */
.preview {
  pointer-events: none;
  user-select: none;

  main {
    pointer-events: none;
    user-select: none;
  }
}

/* Main Layout */
.viewBox {
  position: absolute;
  inset: 0;
  overflow: auto;
}

.container {
  min-height: 100%;
  @include _.flex_column(center, normal);
}

.main {
  @include _.flex_column;
}

.placeHolder {
  flex: 1;
  min-height: _.unit(5);
}

:global(body.mobile) {
  .container {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .main {
    flex: 1;
    align-self: stretch;
    padding: _.unit(4) _.unit(5);
    position: relative;
    background: var(--color-bg-body);
  }

  .placeHolder {
    display: none;
  }
}

:global(body.desktop) {
  .main {
    width: 640px;
    min-height: 640px;
    position: relative;
    padding: _.unit(12)  _.unit(6);
    border-radius: 16px;
    background: var(--color-bg-float);
    box-shadow: var(--color-shadow-2);
  }
}
