import { z } from 'zod';
// Logto OIDC config
export var LogtoOidcConfigKey;
(function (LogtoOidcConfigKey) {
    LogtoOidcConfigKey["PrivateKeys"] = "oidc.privateKeys";
    LogtoOidcConfigKey["CookieKeys"] = "oidc.cookieKeys";
})(LogtoOidcConfigKey || (LogtoOidcConfigKey = {}));
export const logtoOidcConfigGuard = Object.freeze({
    [LogtoOidcConfigKey.PrivateKeys]: z.string().array(),
    [LogtoOidcConfigKey.CookieKeys]: z.string().array(),
});
// Admin console config
export const adminConsoleDataGuard = z.object({
    // Get started challenges
    demoChecked: z.boolean(),
    applicationCreated: z.boolean(),
    signInExperienceCustomized: z.boolean(),
    passwordlessConfigured: z.boolean(),
    socialSignInConfigured: z.boolean(),
    furtherReadingsChecked: z.boolean(),
});
export var AdminConsoleConfigKey;
(function (AdminConsoleConfigKey) {
    AdminConsoleConfigKey["AdminConsole"] = "adminConsole";
})(AdminConsoleConfigKey || (AdminConsoleConfigKey = {}));
export const adminConsoleConfigGuard = Object.freeze({
    [AdminConsoleConfigKey.AdminConsole]: adminConsoleDataGuard,
});
export const logtoConfigKeys = Object.freeze([
    ...Object.values(LogtoOidcConfigKey),
    ...Object.values(AdminConsoleConfigKey),
]);
export const logtoConfigGuards = Object.freeze({
    ...logtoOidcConfigGuard,
    ...adminConsoleConfigGuard,
});
