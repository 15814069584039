@use '@/scss/underscore' as _;

.link {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: inline-flex;
  justify-content: center;

  > svg {
    margin-right: _.unit(2);
  }

  &.primary {
    font: var(--font-label-2);
  }

  &.primary,
  &.inlinePrimary {
    color: var(--color-brand-default);
    text-decoration: none;

    &:active {
      color: var(--color-brand-hover);
    }
  }

  &.secondary {
    color: var(--color-type-secondary);
    text-decoration: underline;
  }
}


:global(body.desktop) {
  .link {
    &.primary:hover,
    &.inlinePrimary:hover {
      color: var(--color-brand-hover);
    }

    &.primary:focus-visible,
    &.inlinePrimary:focus-visible {
      outline: _.border(var(--color-overlay-brand-focused));
    }

    &.secondary:hover,
    &.secondary:active {
      color: var(--color-brand-default);
    }
  }
}
