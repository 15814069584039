// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { colorGuard, brandingGuard, languageInfoGuard, signInGuard, signUpGuard, connectorTargetsGuard } from './../foundations/index.js';
import { SignInMode } from './custom-types.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().max(21),
    color: colorGuard,
    branding: brandingGuard,
    languageInfo: languageInfoGuard,
    termsOfUseUrl: z.string().max(2048).nullable().optional(),
    signIn: signInGuard,
    signUp: signUpGuard,
    socialSignInConnectorTargets: connectorTargetsGuard.optional(),
    signInMode: z.nativeEnum(SignInMode).optional(),
    customCss: z.string().nullable().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().max(21),
    color: colorGuard,
    branding: brandingGuard,
    languageInfo: languageInfoGuard,
    termsOfUseUrl: z.string().max(2048).nullable(),
    signIn: signInGuard,
    signUp: signUpGuard,
    socialSignInConnectorTargets: connectorTargetsGuard,
    signInMode: z.nativeEnum(SignInMode),
    customCss: z.string().nullable(),
});
export const SignInExperiences = Object.freeze({
    table: 'sign_in_experiences',
    tableSingular: 'sign_in_experience',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        color: 'color',
        branding: 'branding',
        languageInfo: 'language_info',
        termsOfUseUrl: 'terms_of_use_url',
        signIn: 'sign_in',
        signUp: 'sign_up',
        socialSignInConnectorTargets: 'social_sign_in_connector_targets',
        signInMode: 'sign_in_mode',
        customCss: 'custom_css',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'color',
        'branding',
        'languageInfo',
        'termsOfUseUrl',
        'signIn',
        'signUp',
        'socialSignInConnectorTargets',
        'signInMode',
        'customCss',
    ],
    createGuard,
    guard,
});
