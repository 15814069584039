@use '@/scss/underscore' as _;

.countryCodeSelector {
  font: var(--font-label-1);
  color: var(--color-type-primary);
  border: none;
  background: none;
  position: relative;
  height: 100%;
  padding-right: _.unit(1);
  padding-left: _.unit(4);
  @include _.flex-row;
  overflow: hidden;
  white-space: nowrap;

  > select {
    flex-shrink: 0;
    appearance: none;
    border: none;
    outline: none;
    background: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;

    option {
      font: var(--font-label-1);
    }
  }

  > svg {
    flex-shrink: 0;
    color: var(--color-type-secondary);
    margin-left: _.unit(1);
    width: 16px;
    height: 16px;
  }
}

:global(body.desktop) {
  .countryCodeSelector {
    font: var(--font-body-2);

    > select option {
      font: var(--font-body-2);
    }

    > svg {
      margin-left: _.unit(2);
      width: 20px;
      height: 20px;
    }
  }
}
