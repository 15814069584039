@use '@/scss/underscore' as _;

.main,
.otherMethods,
.divider {
  margin-bottom: _.unit(4);
}


.createAccount {
  margin-top: _.unit(2);
  text-align: center;
  margin-bottom: _.unit(4);
}

.placeHolder {
  flex: 1;
}


:global(body.desktop) {
  .placeHolder {
    flex: 0;
  }
}
